// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "C_fM";
export var multiVendorBusinessesSection = "C_fF";
export var multiVendorClinetsSection = "C_fS";
export var multiVendorDevExpertiseSection = "C_fP";
export var multiVendorDiscoverWorksSection = "C_fL";
export var multiVendorFeaturesSection = "C_fD";
export var multiVendorIdeaToLifeSection = "C_fK";
export var multiVendorIndustriesSection = "C_fG";
export var multiVendorOurAchievementsSection = "C_fR";
export var multiVendorProcessSection = "C_fH";
export var multiVendorProjLogoSection = "C_fN";
export var multiVendorServicesSection = "C_fC";
export var multiVendorSuccessStoriesSection = "C_fQ";
export var multiVendorTechStackSection = "C_fJ";